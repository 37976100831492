import { ReactNode, useState } from 'react';
import { Block } from 'types/page';
import useElementClickedOutside from 'Hooks/useElementClickedOutside';
import ToolbarManipulatorButton from '../Common/ToolbarManipulatorButton/ToolbarManipulatorButton';
import ToolbarManipulatorPopup from '../Common/ToolbarManipulatorPopup/ToolbarManipulatorPopup';
import { ManipulatorSubmenuAction } from '../types';
import { actionComponents } from './constants';

import styles from './ManipulatorSubmenuActions.module.scss';

interface IProps {
  block: Block;
  enabled?: boolean;
  icon: ReactNode;
  children: Array<ManipulatorSubmenuAction>;
}

const ManipulatorSubmenuActions = ({
  block,
  icon,
  children,
  enabled,
}: IProps) => {
  const [submenuVisible, setSubmenuVisible] = useState(false);

  const { ref } = useElementClickedOutside(() => setSubmenuVisible(false));

  return (
    <ToolbarManipulatorButton
      className={styles.actionButton}
      enabled={enabled}
      onClick={() => setSubmenuVisible((prev) => !prev)}
      reference={ref}
    >
      {icon}
      <ToolbarManipulatorPopup
        className="toolbar-manipulator-submenu"
        isPopupVisible={submenuVisible}
        onPopupClose={() => setSubmenuVisible(false)}
        placement="bottomLeft"
        popupContent={
          <div className={styles.submenu}>
            {children.map((child, index) => {
              const Component =
                child.additionalAction ?? actionComponents[child.type];
              return (
                <Component
                  key={'submenu_' + block.type + index}
                  enabled={enabled}
                  block={block}
                  text={child.text ?? ''}
                />
              );
            })}
          </div>
        }
      />
    </ToolbarManipulatorButton>
  );
};

export default ManipulatorSubmenuActions;
