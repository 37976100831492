import clsx from 'clsx';
import { useState } from 'react';
import { ReactComponent as ColorPalette } from 'Assets/ColorPalette.svg';
import { Block } from 'types/page';
import ColorScheme from 'UILib/ColorScheme/ColorScheme';
import ToolbarManipulatorButton from '../../../Common/ToolbarManipulatorButton/ToolbarManipulatorButton';
import ToolbarManipulatorPopup from '../../../Common/ToolbarManipulatorPopup/ToolbarManipulatorPopup';

import styles from './ColorSchemeAction.module.scss';

interface IProps {
  block: Block;
  enabled: boolean;
}

const BookCoverColorSchemeAction = ({ block }: IProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleColorSchemeClick = (_: string[], index?: number) => {
    block.color.onChangeColorPalette(index);
  };

  return (
    <ToolbarManipulatorButton
      className={clsx(styles.button, { [styles.active]: isModalVisible })}
      onClick={() => setIsModalVisible(true)}
      enabled={true}
    >
      <ColorPalette />
      <ToolbarManipulatorPopup
        isPopupVisible={isModalVisible}
        onPopupClose={() => setIsModalVisible(false)}
        popupContent={
          <div className={styles.colorPaletteWrapper}>
            {block.color.options.map(
              (colorPalette: string[], index: number) => (
                <ColorScheme
                  index={index}
                  colors={colorPalette}
                  onClick={handleColorSchemeClick}
                  isSelected={block.color.selectedColor === index}
                />
              )
            )}
          </div>
        }
      />
    </ToolbarManipulatorButton>
  );
};

export default BookCoverColorSchemeAction;
