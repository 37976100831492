import { useEffect, useState, useRef, RefObject, useMemo } from 'react';
import { EditorState, convertToRaw } from 'draft-js';
import { UseOnClickOutside } from 'utils/UseOnClickOutside';
import { Block } from 'types/page';
import ItemImage from './Items/ItemImage';
import ItemText from './Items/ItemText';
import ItemGradient from './Items/ItemGradient';
import { DraggableHandle } from './Items/Draggable';
import Overlay from './Overlay/Overlay';
import Manipulator, {
  ManipulatorHandle,
  ManipulatorMode,
} from './Overlay/Manipulator';

import styles from './CoverEditor.module.scss';

interface IProps {
  titles?: string[];
  width: number;
  height: number;
  data: any;
  readOnly?: boolean;
  onChangeData?: (data: any) => void;
  onItemsChanged?: (items: any) => void;
}

const CoverEditor = ({
  data,
  height,
  width,
  onChangeData,
  onItemsChanged,
  readOnly,
  titles,
}: IProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const manipulatorRef = useRef<ManipulatorHandle>(null);
  const [selectedItem, setSelectedItem] = useState<DraggableHandle | null>(
    null
  );
  const [items, setItems] = useState(data.items);

  const bookCoverBlock: Block = useMemo(
    () => ({
      id: 0,
      type: 'BookCover',
      children: [],
      color: {
        options: data.colors,
        selectedColor: data.selectedColors,
        onChangeColorPalette: (index: number) => {
          if (onChangeData) onChangeData({ ...data, selectedColors: index });
        },
      },
      image: {
        value: data.image,
        onImageChange: (value: string) => {
          if (onChangeData) onChangeData({ ...data, image: value });
        },
      },
      template: {
        index: data.index,
        titles,
        width,
        height,
        onTemplateSelect: (newData: any) => {
          if (onChangeData) onChangeData({ ...data, ...newData });
        },
      },
      ref: containerRef,
    }),
    [data, onChangeData]
  );

  UseOnClickOutside(containerRef, () => {
    setSelectedItem(null);
  });

  const handleManipulatorUpdate = (
    sender: DraggableHandle,
    deltaX: number,
    deltaY: number,
    refX: number,
    refY: number,
    mode: ManipulatorMode
  ) => {
    const item = items[sender.id];
    if (mode === ManipulatorMode.Transform) {
      item.x += deltaX;
      item.y += deltaY;
    } else if (mode === ManipulatorMode.Scale) {
      const rect = sender.rect();
      if (rect) {
        const centerX = rect.x + rect.width * 0.5;
        const centerY = rect.y + rect.height * 0.5;
        //doing DOT product to project point on edge line and figure out scale 'coeff'
        const abx = refX - centerX;
        const aby = refY - centerY;
        const acx = refX + deltaX - centerX;
        const acy = refY + deltaY - centerY;
        const coeff = (abx * acx + aby * acy) / (abx * abx + aby * aby);

        item.scale *= coeff;
      }
    }
    const updatedItems = [...items];
    setItems(updatedItems); //TODO: SUPER overkill need to find a better way
    if (onItemsChanged) {
      onItemsChanged(updatedItems);
    }
  };

  useEffect(() => {
    setItems(data.items);
    //   if(containerRef.current){
    //     containerRef.current.style.animation = 'none';
    //     setTimeout(()=>{
    //       containerRef.current!.style.animation = '';
    //     }, 10);
    //   }
  }, [data]);

  const handleSelectedItem = (senderRef: RefObject<DraggableHandle>) => {
    const isSameSelected = senderRef.current?.id === selectedItem?.id;
    setSelectedItem(senderRef.current);
    return isSameSelected;
  };

  const handleTextItemChange = (
    senderRef: RefObject<DraggableHandle>,
    editorState: EditorState
  ) => {
    if (senderRef.current) {
      const id = senderRef.current.id;
      const item = items[id];
      const rawEditorState = convertToRaw(editorState.getCurrentContent());
      item.textEditorState = rawEditorState;
      const updatedItems = [...items];
      setItems(updatedItems); //TODO: SUPER overkill need to find a better way

      manipulatorRef.current?.update();
      containerRef.current?.scrollTo(0, 0); //NOTE: worakround to avoid accident scroll when cursor going is to offscreen. (browser limitation)

      // //puish down or up positionReferenceItems
      // const posRefId = senderRef.current.positionReferenceId;
      // if (posRefId) {
      //   const containerTop = containerRef.current!.getBoundingClientRect().top;
      //   const refItem = items[posRefId];
      //   const bottom = senderRef.current.rect()!.bottom;
      //   refItem.y = bottom - containerTop;

      //   const updatedItems = [...items];
      //   setItems(updatedItems); //TODO: SUPER overkill need to find a better way
      // }
    }
  };

  function replaceColors(text: string, colors: string[]) {
    return text.replace(/#COLOR_(\d+)/g, function (match, index) {
      var colorIndex = parseInt(index) - 1;
      if (colorIndex >= 0 && colorIndex < colors.length) {
        return colors[colorIndex];
      } else {
        return match; // No corresponding color found, return the original match
      }
    });
  }

  const processStyle = (style: any) => {
    const outStyle = { ...style };
    for (const key of Object.keys(outStyle)) {
      if (outStyle[key].search('#COLOR_') >= 0) {
        const colors = data.colors[data.selectedColors];
        outStyle[key] = replaceColors(outStyle[key], colors);
      }
    }
    return outStyle;
  };

  const processText = (text: string) => {
    if (text.search('#TITLE') >= 0 && titles && titles.length > 0) {
      text = text.replace('#TITLE', titles[0]);
      return text;
    }
    if (text.search('#SUBTITLE_') >= 0 && titles && titles.length > 2) {
      text = text.replace(/#SUBTITLE_(\d+)/g, function (match, index) {
        var subTitleIndex = parseInt(index);
        if (subTitleIndex >= 0 && subTitleIndex < titles!.length) {
          return titles![subTitleIndex];
        } else {
          return match;
        }
      });
      return text;
    }

    return text;
  };

  const processImage = (url: string) => {
    return url.replace('#IMAGE', data.image);
  };

  const itemFactory = (item: any, parentId: number, index: number) => {
    switch (item.type) {
      case 'Image':
        return (
          <ItemImage
            id={index}
            key={`${index}-${parentId}`}
            image={processImage(item.image)}
            frozen={readOnly || item.frozen}
            width={item.width}
            style={item.style}
            height={item.height}
            scale={item.scale}
            x={item.x}
            y={item.y}
            onSelected={handleSelectedItem}
          />
        );
      case 'Text':
        return (
          <ItemText
            id={index}
            key={`${index}-${parentId}`}
            style={processStyle(item.style)}
            text={processText(item.text)}
            textEditorState={item.textEditorState}
            frozen={readOnly || item.frozen}
            width={item.width}
            height={item.height}
            scale={item.scale}
            x={item.x}
            y={item.y}
            editable={true}
            onSelected={handleSelectedItem}
            onChange={handleTextItemChange}
            font={item.font}
            dynamicWrap={item.dynamicWrap}
            textHAlign={item.textHAlign}
            // positionReferenceId={item.positionReferenceId}
          />
        );

      case 'Gradient':
        return (
          <ItemGradient
            id={index}
            key={`${index}-${parentId}`}
            style={processStyle(item.style)}
            maskImage={item.maskImage}
          />
        );
    }
  };

  const handleClick = () => {
    if (readOnly && onChangeData) {
      onChangeData(data);
    }
  };

  return (
    <div
      className={styles.container}
      id={'CoverEditor'}
      ref={containerRef}
      draggable={false}
      onClick={handleClick}
      style={{ width, height }}
    >
      <div
        className={styles.canvas}
        style={{
          transform: `scale(${width / data.width})`,
          width: data.width,
          height: data.height,
        }}
      >
        {items?.map((item: any, i: number) => {
          return itemFactory(item, data.id, i);
        })}
      </div>

      {!readOnly ? (
        <>
          {selectedItem && (
            <Manipulator
              ref={manipulatorRef}
              item={selectedItem}
              onUpdate={handleManipulatorUpdate}
            />
          )}
          <Overlay block={bookCoverBlock} />
        </>
      ) : null}
    </div>
  );
};

export default CoverEditor;
