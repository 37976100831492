import { useState, useEffect, useRef } from 'react';
import style from './BookCover.module.scss';
import CoverEditor from 'CoverEditor/CoverEditor';
import ColorScheme from 'UILib/ColorScheme/ColorScheme';
import FakeLoading from 'Components/Common/FakeLoading/FakeLoading';
import { getTemplate } from '../CoverEditor/tmp_data';

interface IProps {
  coverImages: string[];
  titles: string[];
  onChange: (coverData: any) => void;
}

const BookCover = (props: IProps) => {
  const [coverDataCatalog, setCoverDataCatalog] = useState<any[]>([]);
  const [coverData, setCoverData] = useState<any>(null);
  const [selectedCoverDataIndex, setSelectedCoverDataIndex] = useState(0);
  const [coverEditorScale, setCoverEditorScale] = useState(1.0);
  const minViewHeight = 900;
  const width = 560;
  const height = 800;
  const thumbnailScaleFactor = 0.1666;

  const handleClickOnCover = (data: any) => {
    setSelectedCoverDataIndex(data.index);
    setCoverData({ ...data });

    props.onChange(data);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const catalog: any = [];
    let index = 0;
    //process images in to templates
    const _data = getTemplate();
    for (const image of props.coverImages) {
      for (const data of _data) {
        const d = {
          ...data,
          image: image,
          index: index,
          selectedColors: index % data.colors.length,
        };
        catalog.push(d);
        index += 1;
      }
    }

    setCoverData(catalog[0]);
    props.onChange(catalog[0]);
    setCoverDataCatalog(catalog);
  }, [props.coverImages]);

  const handleResize = (event: Event) => {
    if (window.innerHeight < minViewHeight) {
      setCoverEditorScale(window.innerHeight / minViewHeight);
    } else {
      setCoverEditorScale(1.0);
    }
  };

  const handleClickOnColor = (colors: string[], index?: number) => {
    const data = { ...coverData, selectedColors: index };
    setCoverData(data);
    props.onChange(data);
  };

  return (
    <div
      className={style.container}
      style={{ transform: `scale(${coverEditorScale})` }}
    >
      {props.coverImages.length === 0 ? (
        <div className={style.loadingContainer}>
          <div className={style.loadingText}>
            Your book cover is currently generating...
          </div>
          <FakeLoading timeForOnePercent={500} textForLoading={null} />
        </div>
      ) : (
        <>
          {coverData && (
            <div>
              <CoverEditor
                titles={props.titles}
                width={width}
                height={height}
                data={coverData}
                readOnly={true}
              />

              <div>
                <div className={style.colorScheme}>
                  {(coverData.colors as any[]).map((item, i) => (
                    <ColorScheme
                      key={i}
                      colors={item}
                      index={i}
                      onClick={handleClickOnColor}
                      isSelected={i === coverData.selectedColors}
                    />
                  ))}
                </div>
                <div className={style.note}>
                  Select a book cover. You will be able to continue editing
                  later
                </div>
              </div>
            </div>
          )}

          <div className={style.thumbnails} style={{ height: height }}>
            {coverDataCatalog.map((item, i) => (
              <div className={style.thubmnail}>
                {selectedCoverDataIndex === i ? (
                  <>
                    <div
                      className={
                        style.selectedFrame + ' ' + style.zoomFadeAnimate
                      }
                    />
                    <div className={style.selectedFrame} />
                  </>
                ) : null}
                <CoverEditor
                  titles={props.titles}
                  onChangeData={handleClickOnCover}
                  width={width * thumbnailScaleFactor}
                  height={height * thumbnailScaleFactor}
                  readOnly={true}
                  data={item}
                />
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default BookCover;
