import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { ReactComponent as ImageIcon } from 'Assets/image.svg';
import { Block } from 'types/page';
import BookRerollComponent from 'Components/BookAIRerollImage/BookRerollComponent';
import ToolbarManipulatorPopup from '../../../Common/ToolbarManipulatorPopup/ToolbarManipulatorPopup';
import ToolbarManipulatorButton from '../../../Common/ToolbarManipulatorButton/ToolbarManipulatorButton';

import styles from './BookAIImageReroll.module.scss';

interface IProps {
  block: Block;
  enabled: boolean;
}

const BookAIImageReroll = ({ block, enabled }: IProps) => {
  const [showInput, setShowInput] = useState(false);

  const originalRef = useRef(null);

  const handleChangeImage = (url: string) => {
    if (block.image?.onImageChange) {
      block.image.onImageChange(url);
    }
  };

  const restoreOriginalImage = () => {
    setShowInput(false);
    // if (!block.image || !originalRef.current) {
    //   return;
    // }
    // if (block.image.onImageChange) {
    //   block.image.onImageChange(originalRef.current);
    // }
    // originalRef.current = null;
  };

  const handleSubmitImage = (url: string) => {
    originalRef.current = url as any;
    setShowInput(false);
  };

  useEffect(() => {
    if (!block.image) {
      return;
    }

    originalRef.current = block.image.value;
  }, []);

  return (
    <ToolbarManipulatorButton
      className={clsx(styles.button, { [styles.active]: showInput })}
      onClick={() => setShowInput(true)}
      enabled={enabled}
    >
      <ImageIcon />
      <ToolbarManipulatorPopup
        isPopupVisible={showInput}
        onPopupClose={restoreOriginalImage}
        className={styles.rerollModal}
        popupContent={
          <BookRerollComponent
            initialImageUrl={block.image.value}
            onChange={handleChangeImage}
            onCancel={restoreOriginalImage}
            onSubmit={handleSubmitImage}
          />
        }
      />
    </ToolbarManipulatorButton>
  );
};

export default BookAIImageReroll;
